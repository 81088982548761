import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
// import Header from './header/Header'
import Footer from './footer/Footer'

export default function HomeLayout() {
    return (
        <Box
            sx={{
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    pb: '5rem',
                }}
            >
                {/* <Header /> */}
                <Outlet />
            </Box>
            <Footer />
        </Box>
    )
}
